import React, { createContext, useState } from "react";
export const AuthContext = createContext();

export const AuthProvider=({children}) => {

    const [user,setLogged]=useState(JSON.parse(localStorage.getItem('user')))
    const authenticated =() =>{
        setLogged(JSON.parse(localStorage.getItem('user')))
    };


    const notAuthenticated =() =>{
        setLogged(null)
    };

return (
    <AuthContext.Provider value={{ user, authenticated,notAuthenticated }}>
    {children}
  </AuthContext.Provider>

);


};