import React, {  useState } from "react";
import { Button } from "@mantine/core";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import useFirebaseGet from "../hooks/useFirebaseGet";
import { useTranslation } from "react-i18next";
import { auth, db } from "../firebase";
import { useNavigate } from "react-router-dom";
import {  ref, set } from "firebase/database";
import MapPreview from "./MapPreview";
// import MapComp from "../components/MapComp";

function AddNewAddress(props) {

  const {addressList,setOpened}=props;
  
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
  const [lat, setLat] = useState(31.963158);
  const [lng, setLng] = useState(35.930359);
  const [address, setAddress] = useState("");
  const [areaId, setAreaId] = useState("");
  const [apartment, setApartment] = useState("");
  const [buildNumber, setBuildNumber] = useState("");
  const [floorNumber, setFloorNumber] = useState("");
  const [landmark, setLandmark] = useState("");

  const { data: deliveryList } = useFirebaseGet(
    "/DeliveryList/" + localStorage.getItem("branch")
  );
  
  const [submiting, setSubmiting] = useState(false);

  const handleSubmittingAddress= async(e)=>{
    e.preventDefault();
    if(auth.currentUser==null){
        navigate('/my-account')
      }
      setSubmiting(true);

      await set(
        ref(
          db,
          "/Users/" +
          auth.currentUser.uid +
            "/user_address_list/"
        ),
        [
          ...addressList,
          {
          address: address,
          apartment: apartment,
          build_number: buildNumber,
          floor_number: floorNumber,
          landmark: landmark,
          lat: JSON.stringify(lat),
          lng: JSON.stringify(lng),
          user_area:deliveryList[areaId]
        }]
      
      );

setOpened(false)

  }

  return  (
    <div className="container ">
      <div className="add-address-div">
       <MapPreview lat={lat} setLat={setLat} setLng={setLng} setAddress={setAddress} lng={lng} address={address} />
        <form action="" className="mt-5" onSubmit={handleSubmittingAddress}>
        <FloatingLabel
            controlId="floatingTextarea"
            label={t("delivery.addressdetail")}
            className="mb-3"
          >
            <Form.Control
              as="textarea"
              minLength={6}
              placeholder={`enter ${t("delivery.addressdetail")}`}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              dir={i18n.language==='ar'?'rtl':''}
              required
            />
          </FloatingLabel>
          <div className="row">
            <div className="col-6">
              <FloatingLabel
                controlId="floatingSelect"
                className="mb-3"
                label={t("delivery.choosearea")}
              >
                <Form.Select
                  aria-label="Floating label select example"
                  onChange={(e) => setAreaId(e.target.value)}
                  required as="select" custom
                  
                >
                        <option value={''} selected disabled>...</option>

                  {deliveryList &&
                    Object.values(deliveryList).map((place) => (
                      <option key={place.id} value={place.id}>
                        {place.area}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label={t("delivery.floor")}
                className="mb-3"
              >
                <Form.Control
                  type="text"

                  placeholder={`enter your ${t("delivery.floor")}`}
                  value={floorNumber}
                  onChange={(e) => setFloorNumber(e.target.value)}
                  dir="ltr"
                  required
                />
              </FloatingLabel>
            </div>
            <div className="col-6">
              <FloatingLabel
                controlId="floatingInput"
                label={t("delivery.building")}
                className="mb-3"
              >
                <Form.Control
                  type="text"

                  placeholder={`enter your ${t("delivery.building")}`}
                  value={buildNumber}
                  onChange={(e) => setBuildNumber(e.target.value)}
                  dir="ltr"
                  required
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingInput"
                label={t("delivery.apartment")}
                className="mb-3"
              >
                <Form.Control
                  type="text"

                  placeholder={`enter your ${t("delivery.apartment")}`}
                  value={apartment}
                  onChange={(e) => setApartment(e.target.value)}
                  dir="ltr"
                  required
                />
              </FloatingLabel>
            </div>
          </div>

          <FloatingLabel
            controlId="floatingTextarea"
            label={t("delivery.mark")}
            className="mb-3"
          >
            <Form.Control
              as="textarea"
              minLength={6}
              placeholder={`enter ${t("delivery.mark")}`}
              value={landmark}
              onChange={(e) => setLandmark(e.target.value)}
              dir={i18n.language==='ar'?'rtl':''}
              style={{ height: "100px" }}
              required
            />
          </FloatingLabel>

<div className="w-100 d-flex justify-content-end">
          <Button
          radius="sm"
          className="btn-primary-dark rounded-pill mt-2"
          type='submit'
          loading={submiting ? true : false}

        >
          {t("delivery.addaddress")} 
        </Button>
        </div>
        </form>
      </div>
    </div>
  
  );
}

export default React.memo(AddNewAddress);
