import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import useFirebaseGet from "../hooks/useFirebaseGet";

const MyOrders = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const { data: orders } = useFirebaseGet(
    "/UserOrders/" + localStorage.getItem("branch") + "/" + user.id
  );
const navigate=useNavigate()
  const getOrderDetails = (id) => {

    navigate('/order-details',{state:{
      order:orders[id]
    }})
  };
  return (
    <div className="my-orders-div my-5">
      <div className="container">
        <div className="row gx-4 ">
          {orders &&
            Object.values(orders).map((order) => (
              <div
                className=" col-lg-4 col-md-6 col-12  my-4"
                key={order.order_id}
              >
                <div className="order-card rounded  h-100 ">
                  <div
                    className="d-flex justify-content-between px-3 py-1"
                    style={{ backgroundColor: "#353330" }}
                  >
                    <p className="p-0 m-0 text-white">
                      {" "}
                      {t("orders.orderdate")}:
                    </p>
                    <p className="p-0 m-0 text-white"> {order.order_id}</p>
                  </div>

                  <div className="d-flex justify-content-between px-3 py-1">
                    <p className="p-0 m-0 "> {t("orders.orderstate")}:</p>
                    <p className="p-0 m-0 "> {order.order_status}</p>
                  </div>

                  <div className="d-flex justify-content-between px-3 py-1">
                    <p className="p-0 m-0 "> {t("orders.totalprice")}:</p>
                    <p className="p-0 m-0 ">
                      {" "}
                      {order.total_price}
                      {t("products.jod")}{" "}
                    </p>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      className="  mb-2 btn-primary-dark"
                      onClick={(e) => getOrderDetails(order.order_id)}
                    >
                      {t("orders.orderdetails")}
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MyOrders;
