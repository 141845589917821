import { Group } from '@mantine/core';
import { useEffect, useState } from 'react';

import SubCategories from './SubCategories';


const Categories = ({ categories }) => {

    const [active,setActive]=useState(0);
    const [subCategories,setSubCategories]=useState(categories[active].sub_categories)
   console.log(subCategories)
    useEffect(()=>{
        setSubCategories(categories[active].sub_categories)

    },[categories])

    const getSubCategories=(e)=>{
       const id=parseInt(e.target.id)
        setActive(id)
        const category=categories[id]
        setSubCategories(category.sub_categories)
    }


    return (
<div className="categories">
        <div className="categories-section">
            <div className="container">
                <Group position="center">
                    {categories.map((category,index) => (
                        <a  onClick={getSubCategories} id={index} key={category.id} className={` curser-pointer text-capitalize mx-2 text-primary-dark text-decoration-none font-500 ${index===active? 'border-bottom border-dark ':''}`}>{category.name}</a>
                    ))}
                    </Group>
            </div>
        </div>

{ subCategories.length>0 ?
    <SubCategories categories={subCategories} />
:
<div className="container">
<p className='my-5 text-secondary'>There's no subcategories for this category</p>
</div>

}
</div>
    );
}

export default Categories;