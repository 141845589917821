import SearchProducts from "../components/SearchProducts";
import { ArrowNarrowLeft } from 'tabler-icons-react';
import { ActionIcon } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const SearchMobile = () => {
    const navigate = useNavigate();

  return (
    <>
    <div className="container  d-flex justify-content-between align-items-center" dir="ltr">
       <ActionIcon  variant="filled" color={'dark'} onClick={() => navigate(-1)}>
        <ArrowNarrowLeft
    size={24}
    strokeWidth={2}
  />
  </ActionIcon>
        <SearchProducts />
    </div>
            <div style={{height:"50vh"}} className={'d-flex justify-content-center align-items-center'}>

                <p>{t('products.searchresults')}</p>
            </div>
            </>
  );
};

export default SearchMobile;
