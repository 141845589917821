
import { useContext, useEffect, useState } from "react";
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from "react-i18next";
import useFirebaseGet from "../hooks/useFirebaseGet";
import { useNavigate } from "react-router-dom";
import { BranchContext } from "../hooks/BranchContext";

const BranchSelection = () => {

    const { branchSelected } = useContext(BranchContext)


    const navigate = useNavigate();
    const [branch, setBranch] = useState('')
    //locales
    const { t, i18n } = useTranslation();


    

    const { data: branches } = useFirebaseGet('/branches')

    useEffect(()=>{

if(Object.values(branches).length===1){

   localStorage.setItem('branch', Object.values(branches)[0]['id']);
   branchSelected(Object.values(branches)[0]['id']);
   navigate('/');

}

    },[branches])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (branch !== '') {
            localStorage.setItem('branch', branch);
            branchSelected(branch);
            navigate('/');

        }

    }
    return (

        <div className="branches-div  justify-content-center align-items-center " id="balloon-container">

            <div className="branches-card d-flex justify-content-center">


                {branches ?
                    <div className="container py-5 px-4">
                        <h5 className="">{t('branches.selectbranch')}</h5>
                        <form className="d-flex justify-content-center" onSubmit={handleSubmit}>
                            <select className="form-control form-select rounded-0 " onChange={(e) => setBranch(e.target.value)}>
                               <option disabled selected value={''}>...</option>
                                {Object.values(branches).map((branch) => (
                                    <option value={branch.id} key={branch.id}>{branch['name_' + i18n.language]}</option>
                                ))}
                            </select>
                            <button className="btn btn-dark rounded-0 ">{t('buttons.confirm')}</button>
                        </form>
                    </div> :
                    <Spinner animation="border" className="my-5" variant="dark" />

                }
            </div>
        </div>




    );
}

export default BranchSelection;