import { t } from "i18next";

import CartImg from "../imgs/cart.png";
const CartIconNull = () => {
 

  return (
    <div className="cart-icon d-flex justify-content-between align-items-center">
       <h5 className="text-uppercase hide-for-mobile pt-3 ">00.00\{t('navbar.cart')}</h5> 
      <div className="position-relative">
        <img src={CartImg} alt="" width={"30px"} 

       /> 
        <p className="position-absolute" style={{
            top:"60%",
            left:"50%",
            transform:"translate(-50%,-50%)"

        }}>0</p>
      </div>
    </div>
  );
};

export default CartIconNull;
