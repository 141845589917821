import useFetch from "../hooks/useFetch";
import Categories from "../components/Categories";
// import { Carousel } from "@mantine/carousel";
import { Loader } from "@mantine/core";
import Carousel from "react-bootstrap/Carousel";
import MyLoader from "../components/MyLoader";

const Home = () => {

  const { data: categories } = useFetch("categories");
  const { data: ads } = useFetch("ads");
  return (
    <div className="home mt-3">
      <div className=" mb-4  home-1st-section  d-lg-flex justify-content-between align-items-center ">
        {ads ? (
          <Carousel className="w-100">
            {Object.values(ads).map((ad) => (
              <Carousel.Item key={ad.id}>
                <img src={ad.ads} alt="" className="ads-slider-img" />
              </Carousel.Item>
            ))}
          </Carousel>
        ) : (
          
<MyLoader/>
        )}
      </div>
      {categories && <Categories categories={categories} />}
    </div>
  );
};

export default Home;
