import { Button, Group } from "@mantine/core";
import { t } from "i18next";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Check } from "tabler-icons-react";
import BranchReceipt from "../components/BranchReceipt";
import DoorDelivery from "../components/DoorDelivery";
import deliveryPng from '../imgs/doordelivery.png'

const DeliveryDetails = () => {
  const [doorDelivery, setDoorDelivery] = useState(true);
  const [img,setImg]=useState(deliveryPng)
 const location=useLocation()
 const navigate=useNavigate()
 useEffect(()=>{
if(!location.state){
  navigate('/cart')
}


 })
  return (
    <div className="delivery-details-section">
       <div className="w-75 mx-auto d-block mt-5">
      <div className="d-lg-flex justify-content-between align-items-center">

<h2 className="text-primary-dark">{t("delivery.choosehow")}</h2>
<img src={img} alt="" className="payment-imgs"  />

</div>
<hr />
</div>
      <div className="container">
        <div className="delivery-details-div text-white  my-4 p-4">
          <div className="delivery-method mb-3">
            <Group spacing="xl" position="center">
              <Button
                radius="sm"
                className="btn-primary-dark rounded-pill"
                onClick={(e)=>setDoorDelivery(false)}
              >
                 {!doorDelivery ? (
                  <Check size={22} strokeWidth={2}  />
                ) : (
                  " "
                )}
                {t("delivery.frombranch")}
              </Button>
              <Button
                radius="sm"
                className="btn-primary-dark rounded-pill"
                onClick={(e)=>setDoorDelivery(true)}

              >
                {doorDelivery ? (
                  <Check size={22} strokeWidth={2}  />
                ) : (
                  " "
                )}

                {t("delivery.door")}
              </Button>
            </Group>
          </div>


                   {!doorDelivery && <BranchReceipt setImg={setImg} />} 
                   {doorDelivery && <DoorDelivery setImg={setImg}/>} 

        </div>
      </div>
    </div>
  );
};

export default DeliveryDetails;
