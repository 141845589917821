import { useLocation } from "react-router-dom";
import Products from "../components/Products";

const SearchResults = ({ route, navigate }) => {
    const location = useLocation();

    const routeValue = location.state.routeValue;
    const header = location.state.header;
    return ( 


<>
<div className="container">
        { <Products route={routeValue} page={"0"} header={header}/>}
        </div>
        </>


     );
}
 
export default SearchResults;