import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n.js";
import { BranchProvider } from "./hooks/BranchContext";
import { AuthProvider } from "./hooks/AuthContext";
import { BrowserRouter as Router } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { Loader } from "@mantine/core";




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BranchProvider>
      <AuthProvider>
        <Suspense fallback={<div className="d-flex justify-content-center align-items-center my-5 w-100" style={{height:"100vh"}}>
          <Loader color="red" size="lg" />
        </div>}>
          <Router>
            <MantineProvider withGlobalStyles withNormalizeCSS>
              <App />
            </MantineProvider>
          </Router>
        </Suspense>
      </AuthProvider>
    </BranchProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
