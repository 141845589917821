import { t } from "i18next";
import { useLocation } from "react-router-dom";

const OrderDetails = ({ route, navigate }) => {
  const location = useLocation();
  const order = location.state.order;

  return (
    <div className="container mt-3">
      <h3>{t("orders.orderdetails")}</h3>
      <div className="row">
        {order &&
          Object.values(order.list_of_product).map((product) => (
            <div className="col-lg-3 col-md-6 col-12  mt-3">
              <div className="product-img-div ">
                <img
                  className="product-img curser-pointer"
                  src={product.image}
                  alt=""
                  width={"100%"}
                />
              </div>
              <div className="d-flex justify-content-center">
                

              <span className="my-1  btn-secondary-dark rounded-pill ">
                {t("products.jod")} {product.total_price}
              </span>
              <br />
              <span className="mx-1 my-1  btn-secondary-dark rounded-pill ">
                {t("products.quantity")}:  {product.quantity}
              </span>
              </div>
            </div>
          ))}
      </div>
      <hr />
      <div className="d-flex justify-content-center mb-3">
                

              <h3 className="my-1  btn-secondary-dark rounded-pill ">
                {t("products.jod")} {order.total_price}
              </h3>
            
              </div>
    </div>
  );
};

export default OrderDetails;
