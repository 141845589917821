import { useEffect, useState } from "react";
import {db} from "../firebase";
import {onValue, ref} from "firebase/database";


const useFirebaseGet = (url) => {

    const [data,setData]=useState([]);

    useEffect(()=>{

        onValue(ref(db,url), (snapshot)=>{
             const data= snapshot.val();
             
             if(data!== null){
                 setData(data)
             }
             else {
                setData([])
             }
     })
     
     },[url])



    return {data}
}
 
export default useFirebaseGet;