import { useEffect } from "react";


const MapPreview = (props) => {
    const { lat, lng, setLat, setLng } = props;

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        });
      }, []);
  
  
    return ( 

        <div className="map-container">

<iframe className="mt-3" height={'100%'}  width={'100%'} src={`//maps.google.com/maps?q=${lat},${lng}&z=15&output=embed`} allow="geolocation"></iframe>



</div>


     );
}
 
export default MapPreview;