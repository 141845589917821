import { useParams } from "react-router-dom";
import ModalData from "../components/ModalData";
import useFetch from "../hooks/useFetch";

const ProductShow = () => {
    const {id}=useParams()
    const { data: product } = useFetch("product/"+id);

   

    return ( 
        <div className="product-details my-4">
            <div className="container">
        {product &&
        <ModalData
        product={product}
        opened={""}
        onSubmitCart={""}
        onClose={""}
      />
    }
    </div>
    </div>
     );
}
 
export default ProductShow;