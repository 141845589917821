import { Loader } from "@mantine/core";


const MyLoader = () => {
    return ( 


<div className="d-flex justify-content-center my-5 w-100" style={{height:"100vh"}}>
          <Loader color="blue" size="lg" />
        </div>

     );
}
 
export default MyLoader;