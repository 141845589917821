import useFirebaseGet from "../hooks/useFirebaseGet";
import {  useNavigate } from "react-router-dom";
import { t } from "i18next";
import {  Button, Radio } from "@mantine/core";
import { Plus } from "tabler-icons-react";
import {  Modal } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import AddNewAddress from "./AddNewAddress";
import deliveryPng from '../imgs/doordelivery.png'

const DoorDelivery = (props) => {
  const {setImg}=props

  const [opened, setOpened] = useState(false);
  const [error, setError] = useState(null);
const navigate=useNavigate()
  const { i18n } = useTranslation();
const [address,setAddress]=useState(null)
  const user = JSON.parse(localStorage.getItem("user"));


  const { data: addressList } = useFirebaseGet(
    "/Users/" + user.id + "/user_address_list"
  );
  useEffect(()=>{

    setImg(deliveryPng)
    
        })
  const handleAddressSubmit =(e)=>{
    e.preventDefault();
    if(address===null){
      setError(t("delivery.chooseaddresserr"))
    
    }
else {
  setError(null)
  
navigate('/checkout',{state:{
  branch:false,  address:addressList[address]}})

}
}

  
  return (
    <div className="door-delivery-div">


<Button
        radius="xl"
        className="btn-primary-dark rounded-pill"
        onClick={(e) => setOpened(true)}
      >
        {t("delivery.addnewaddress")} <Plus size={22} strokeWidth={2} />
      </Button>
<form onSubmit={handleAddressSubmit}>
<div className="address-list-form bg-white p-3 rounded my-3">

      <Radio.Group
      value={address}
      orientation="vertical"
      label={t("delivery.chooseaddress")}
      onChange={setAddress}
      required as="select" custom

    >
      {Object.keys(addressList).map((id)=>(


          <Radio value={id} label={addressList[id].address} key={id}/>



      ))

      }
     
    </Radio.Group>
      </div>
      {error && <span className="text-danger mb-3">*{error}*</span>
}
<div className="d-flex justify-content-end mt-2">


          <Button
            radius="xl"
            className="btn-primary-dark rounded-pill"
            type="submit"
          >
            {t("cart.checkout")}{" "}
          </Button>



</div>
</form>
     
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t("delivery.addnewaddress")}
        size="lg"
        dir={i18n.language === "ar" ? "rtl" : ""}
        overlayOpacity={0.55}
        overlayBlur={3}
      >
        { <AddNewAddress addressList={Object.values(addressList)} setOpened={setOpened} />}
      </Modal>
    </div>
  );
};

export default DoorDelivery;
