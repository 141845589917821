import useFetch from "../hooks/useFetch";
import Categories from "../components/Categories";
import MyLoader from "../components/MyLoader";

const Products= () => {
    const { data: categories } = useFetch("categories");

    return ( 

<div className="products-page-section mt-5">





{categories ? <Categories categories={categories} />

:
<MyLoader/>

}

</div>



     );
}
 
export default Products