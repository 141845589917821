import { Link } from "react-router-dom";

const Notfound = () => {
    return ( 

<div className="d-flex justify-content-center align-items-center" style={{
    'height':'100vh',
    
}}>

<div className="notfound text-center">
<h3>404 Not found</h3>
<Link to='/'>Back To Home Page</Link>
</div></div>
     );
}
 
export default Notfound;