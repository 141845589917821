import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth, db } from "../firebase";
import { useState, useContext } from "react";
import { Message } from "tabler-icons-react";
import { ref, set } from "firebase/database";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { AuthContext } from "../hooks/AuthContext";
import { useTranslation } from "react-i18next";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const { authenticated } = useContext(AuthContext);
  const { t } = useTranslation();

  const [phoneNumber, setPhoneNumber] = useState("+962");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [switchToOtp, setSwitchToOtp] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();

  //generating capatcha
  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recapatcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    );
  };

  const requestOTP = (e) => {
    e.preventDefault();
    if (phoneNumber.length >= 12 && phoneNumber[0] ==="+") {
      setSpinner(true);

      generateRecaptcha();
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setSwitchToOtp(true);
          setSpinner(false);
        })
        .catch((error) => {
          // Error; SMS not sent
          console.log(error);
        });
    } else if (phoneNumber[0] !=="+") {
      setPhoneNumberError("the phone number should start with + ");
    }
    else  {
      setPhoneNumberError("the phone number is not correct ");
    }
  };

  const verifyOTP = (e) => {
    e.preventDefault();
    setSpinner(true);

    if (otpCode.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otpCode)
        .then((result) => {
          const user = result.user;
          storeUser(user.uid);
          localStorage.setItem(
            "user",
            JSON.stringify({
              id: user.uid,
              phone: user.phoneNumber,
              name: name,
            })
          );
          authenticated();
          navigate(1);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("otp must be 6 digits");
    }
  };
  const storeUser = (uid) => {
    set(ref(db, "/Users/" + uid), {
      id: uid,
      name,
      phone: phoneNumber,
      points: "0",
    });
  };
  return (
    <div className="registration">
      <div className=" registration-section d-flex justify-content-center align-items-center">
        <form
          action=""
          className={`w-lg-25 w-sm-75 ${!switchToOtp ? "" : "d-none"}`}
          onSubmit={requestOTP}
        >
          <FloatingLabel
            controlId="floatingInput"
            label={t("register.username")}
            className="mb-3"
          >
            <Form.Control
              type="text"
              minLength={6}
              value={name}
              placeholder="enter your username"
              onChange={(e) => setName(e.target.value)}
              dir="ltr"
              required
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label={t("register.phone")}
            className="mb-3"
          >
            <Form.Control
              type="tel"
              minLength={11}
              placeholder="enter your phone"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              dir="ltr"
              required
            />
          </FloatingLabel>
          <span className="text-danger">{phoneNumberError}</span>
          <br />

          <button
            type="submit"
            className="form-control btn btn-dark "
            disabled={phoneNumber.length < 11 ? true : false}
          >
            {t("register.reqOTP")}
            {!spinner ? (
              <Message size={22} strokeWidth={1}  />
            ) : (
              <Spinner
                animation="border"
                variant="dark"
                size="sm"
                className="mx-3"
              />
            )}
          </button>
          <div id="recapatcha-container"></div>
        </form>

        <form
          action=""
          onSubmit={verifyOTP}
          className={`w-lg-25 w-sm-75 ${switchToOtp ? "" : "d-none"}`}
        >
          <div>
            <h6 className="mb-3">
              {" "}
              {t("register.OTPMsg")}
              <span className="text-success" dir="ltr">
                {phoneNumber}
              </span>
            </h6>
            <FloatingLabel
              controlId="floatingInput"
              label={t("register.OTP")}
              className="mb-3"
            >
              <Form.Control
                type="number"
                minLength={6}
                className="form-control"
                placeholder="please put your OTP"
                value={otpCode}
                onChange={(e) => setOtpCode(e.target.value)}
                dir="ltr"
              />
            </FloatingLabel>

            <button
              type="submit"
              className="form-control btn btn-dark "
              disabled={otpCode.length !== 6 ? true : false}
            >
              {t("register.confirmOTP")}{" "}
              {spinner && (
                <Spinner
                  animation="border"
                  variant="dark"
                  size="sm"
                  className="mx-3"
                />
              )}
            </button>
            <br />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
