import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { User } from "tabler-icons-react";
import { useContext } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { AuthContext } from "../hooks/AuthContext";
// import { BuildingStore } from "tabler-icons-react";
import { BranchContext } from "../hooks/BranchContext";
import logo from "../imgs/logo.png";
import i18next from "i18next";
import { HashLink } from "react-router-hash-link";
import { ShoppingCart } from 'tabler-icons-react';
import NavbarMobile from "./NavbarMobile";
import CartIcon from "./CartIcon";

const NavbarComp = ({ links,bg,fontColor,btnStyle }) => {
  const location = useLocation();
  const { user, notAuthenticated } = useContext(AuthContext);
  const { branch } = useContext(BranchContext);
  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const logout = (e) => {
    signOut(auth);
    localStorage.setItem("user", null);
    notAuthenticated();
  };

  return (
    <>
    <div className="container">
      <div className="hide-for-mobile">
    <div className="d-flex justify-content-between align-items-center hide-for-mobile">
   <img src={logo} alt="" width={'10%'} className={'my-2'}/>
   <div className="d-flex justify-content-between align-items-center">

            {links.map((link) => (
              <Link
                key={link.id}
                className={`mx-3 mt-1 ${fontColor} ${location.pathname===link.link? 'border-bottom border-dark ':''} text-decoration-none`}
                to={link.link}
              >
                {link.icon} {t("navbar." + link.title)}{" "}
              </Link>
            ))}
            {!user && (
              <Link
                className={`mx-3 ${btnStyle}  text-decoration-none`}
                to={"/my-account"}
              >
                <User size={22} strokeWidth={1} className={'text-white'}  />{" "}
                {t("navbar.myaccount")}
              </Link>
            )}
           
            {user && (
              <Dropdown className={btnStyle}>
                <Dropdown.Toggle
                  variant=""
                  className={`p-0 mx-2 ${btnStyle} `}
                  id="dropdown-basic"
                >
                  <User size={22} strokeWidth={2} className={'text-white'}/> {user.name}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    className="text-decoration-none text-pink"
                    onClick={logout}
                  >
                    {t("register.signout")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
       

            <span
              className={`langbtn ${fontColor} mx-2 mt-1`}
              onClick={() => changeLanguage(t("navbar.langlink"))}
            >
              {t("navbar.langlink")}
              <img
                className="mx-1"
                src={require("../imgs/" + i18n.language + ".png")}
                alt=""
                width="20px"
              />
            </span>
         
   </div>
        </div>
        </div>

        <NavbarMobile links={links} />

        </div>
    </>
  );
};

export default NavbarComp;
