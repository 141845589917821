// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getDatabase} from 'firebase/database';
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "amman-corner.firebaseapp.com",
  databaseURL: "https://amman-corner-default-rtdb.firebaseio.com",
  projectId: "amman-corner",
  storageBucket: "amman-corner.appspot.com",
  messagingSenderId: "777381198786",
  appId: "1:777381198786:web:31211db9fb91a347a7b45b",
  measurementId: "G-L9Q46P98VC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db=getDatabase(app);
const auth=getAuth(app);
export {db , auth }