import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Search } from "tabler-icons-react";

const SearchProducts = () => {
  const [searchValue, setSearchValue] = useState("");
  const navigate=useNavigate()

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchValue!==""){

        navigate('/search',{state:{
           routeValue:`products/search/name=${searchValue}/page=`,
            header:searchValue
        }})
          
          }
    }

  
  return (
    <form
      action=""
      className="d-flex align-items-center w-100"
      onSubmit={handleSearch}
    >
      <input
        type="text"
        value={searchValue}
        className='w-100'
        onChange={(e) => setSearchValue(e.target.value)}
        required
      />
      <button className="btn btn-sm" type="submit">
        <Search size={24} strokeWidth={2} color={"black"} />
      </button>
    </form>
  );
};

export default SearchProducts;
