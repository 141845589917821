import './App.css';
import {BrowserRouter as Router , Route , Routes,Navigate,useLocation} from 'react-router-dom';
import Home from './pages/Home';
import { useContext, useEffect, useState } from 'react';
import Notfound from './components/Notfound';
import 'bootstrap/dist/css/bootstrap.min.css';
import BranchSelection from './pages/BranchSelection';
import {  useTranslation } from "react-i18next";
import { BranchContext } from './hooks/BranchContext';
import Register from './pages/Register';
import { AuthContext } from './hooks/AuthContext';
import Footer from './components/Footer';
import Cart from './pages/Cart';
import DeliveryDetails from './pages/DeliveryDetails';
import Checkout from './pages/Checkout';
import Products from './pages/Products';
import useFirebaseGet from './hooks/useFirebaseGet';
import MyOrders from './pages/MyOrders';
import Navbar from './components/NavbarComp';
import SearchResults from './pages/SearchResults';
import SearchMobile from './pages/SearchMobile';
import OrderDetails from './pages/OrderDetails';
import ProductShow from './pages/ProductShow';
import { ShoppingCart } from 'tabler-icons-react';


function App() {


 const options={

  whiteNav:{
    bg:'bg-white' ,
    fontColor:'text-primary-dark',
    btnStyle:'btn-dark',
  },

  pinkNav:{
    bg:'bg-pink' ,
    fontColor:'text-white',
    btnStyle:'btn-babyblue',
  }
}
 const [currentOptions,setCurrentOptions]=useState(options.whiteNav)

 const location = useLocation();
useEffect(()=>{
  //switching navbar style due to condition
if(true){
  setCurrentOptions(options.whiteNav)
}
else {
  setCurrentOptions(options.pinkNav)}


},[location])

 const{ branch,branchSelected}=useContext(BranchContext);
 const {user}=useContext(AuthContext)
 const { data: branches } = useFirebaseGet('/branches')

    useEffect(()=>{
if(!localStorage.getItem('branch')){
if(Object.values(branches).length===1){

   localStorage.setItem('branch', Object.values(branches)[0]['id'])
   localStorage.setItem('branchName', Object.values(branches)[0]['name_en']);
   branchSelected(Object.values(branches)[0]['id']);
}}

    },[branches])








  const navbar=[
    {id: 1, title: "home",       link: "/" ,icon:""},
    {id: 2, title: "products",   link: "/products" ,icon:""},
    {id: 2, title: "cart",   link: "/cart" ,icon:<ShoppingCart
   
    strokeWidth={2}
  />},
    {id: 4, title: "myorders",  link: "/my-orders" ,icon:""},

      ];
      const {i18n} = useTranslation();
      
  return (
    <div className="App" dir={i18n.language==='ar'?'rtl':''}>
      <header className="">
     
      <Navbar links={navbar} {...currentOptions} />
      

        <div className="content">
      <Routes>
        <Route exact path='/' element={< Home />}></Route>
        <Route path='/branches' element={<BranchSelection />}></Route>
        <Route path='/my-account' element={!user ?  <Register /> :<Navigate to={'/#'}/> }></Route>
        <Route path='/cart' element={!user ?  <Register /> :<Cart/> }></Route>
        <Route path='/delivery-details' element={!user ? <Register />: <DeliveryDetails />  }></Route>
        <Route path='/checkout' element={!user ? <Register />: <Checkout />  }></Route>
        <Route path='/my-orders' element={!user ? <Register />: <MyOrders />  }></Route>
        <Route path='/order-details' element={!user ? <Register />: <OrderDetails />  }></Route>

        <Route path='/products' element={<Products />}></Route>
        <Route path='/search' element={<SearchResults />}></Route>
        <Route path='/search-page' element={<SearchMobile />}></Route>
        <Route path='/products/:id' element={< ProductShow />}></Route>

        <Route path='*' element={< Notfound />}></Route>

      </Routes>
      </div>
     

     <Footer/>
      </header>
    </div>
  );
}

export default App;
