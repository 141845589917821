import { Button } from "@mantine/core";
import i18next, { t } from "i18next";
import useFirebaseGet from "../hooks/useFirebaseGet";
import { useNavigate } from "react-router-dom";
import deliveryPng from '../imgs/doordelivery.png'
import branchPng from '../imgs/branchreceipt.png'
import { useEffect } from "react";

const BranchReceipt = (props) => {
   const {setImg}=props
    const navigate=useNavigate()

    const { data: branch } = useFirebaseGet('/branches/'+localStorage.getItem('branch'))
   
    useEffect(()=>{

setImg(branchPng)

    })
    const handleReceipt=()=>{


        navigate('/checkout',{state:{
            branch:true,  address:null}})

    }
    return ( 

<div className="branch-receipt">

<div className="container">
{ branch &&
<div>
<h5 className="">{t('delivery.branch')}: {branch['name_'+i18next.language]}</h5>
<h5 className="">{t('delivery.address')}: {branch['address_'+i18next.language]}</h5>

<iframe className="mt-3" height={'300px'}  width={'100%'} src={`//maps.google.com/maps?q=${branch.lat},${branch.lng}&z=15&output=embed`}></iframe>

<div className="d-flex justify-content-end mt-2">

<Button
            radius="xl"
            className="btn-primary-dark rounded-pill"
            onClick={handleReceipt}
          >
            {t("cart.checkout")}{" "}
          </Button>
</div>
</div>

}


</div>
</div>


     );
}
 
export default BranchReceipt;