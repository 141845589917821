import useFirebaseGet from "../hooks/useFirebaseGet";
import { auth, db } from "../firebase";
import { NumberInput, Group, ActionIcon, Button } from "@mantine/core";
import { t } from "i18next";
import { Trash } from "tabler-icons-react";
import { ref, remove, set } from "firebase/database";
import { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";

const Cart = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [totalPrice, setTotalPrice] = useState(0);
  const [lastId, setLastId] = useState("");
  const { data: cart } = useFirebaseGet(
    "/Cart/" + localStorage.getItem("branch") + "/" + user.id
  );
  //total Price
  useEffect(() => {
    let cartTotal = 0;
    if (cart) {
      Object.values(cart).map((product) => {
        cartTotal += parseFloat(JSON.parse(product.total_price));
      });
    }
    setTotalPrice(cartTotal.toFixed(2));
  }, [cart]);

  const handleIncrease = (e, id, newValue) => {
    cart[id].total_price = (newValue * parseFloat(cart[id].price)).toFixed(2);
    cart[id].quantity = JSON.stringify(newValue);
    setLastId(id + newValue);
  };

  const handleCheckout = async() => {
    if (lastId !== "") {
     await set(
        ref(
          db,
          "/Cart/" + localStorage.getItem("branch") + "/" + auth.currentUser.uid
        ),{
        ...cart 

        }
      );
    }
    navigate("/delivery-details",{state:{
     legal:true}});
  };
  const handleDelete = (e, id) => {
    remove(
      ref(
        db,
        "/Cart/" + localStorage.getItem("branch") + "/" + user.id + "/" + id
      )
    );
  };

  return (
    <div className="cart-main-div mt-5">
      <div className="container">
        {cart &&
          Object.values(cart).map((product) => (
            <div key={product.id}>
              <div className="d-lg-flex justify-content-between align-items-center">
                <div className="">
                  <img
                    src={product.image}
                    alt=""
                    className="product-image-cart mx-auto d-block"
                  />
                </div>
                <div>
                  <div className="d-flex justify-content-end">
                    <ActionIcon
                      color="red"
                      radius={"xl"}
                      variant="filled"
                      onClick={(e) => handleDelete(e, product.id)}
                    >
                      <Trash size={20} strokeWidth={2} />
                    </ActionIcon>
                  </div>
                  <h5 className="text-center">{product.name}</h5>{" "}
                  <div className="d-lg-flex d-md-flex w-sm-50 mx-auto d-block justify-content-between  ">
                    <h4 className="btn-secondary-dark rounded-pill  mx-2">
                      {t('cart.quantity')}: {product.quantity}
                    </h4>
                    <h4 className="btn-secondary-dark rounded-pill  mx-2">
                      {JSON.parse(product.total_price)} {t("products.jod")}{" "}
                    </h4>
                    <Group spacing={5} className="mx-3 mb-2">
                      <ActionIcon
                        color="dark"
                        variant="outline"
                        radius="xl"
                        onClick={(e) =>
                          handleIncrease(
                            e,
                            product.id,
                            parseInt(product.quantity) - 1
                          )
                        }
                        disabled={parseInt(product.quantity) === 1 ? true : ""}
                      >
                        –
                      </ActionIcon>

                      <NumberInput
                        hideControls
                        value={parseInt(product.quantity)}
                        onChange={(e, val) =>
                          handleIncrease(e, product.id, val)
                        }
                        max={10}
                        min={1}
                        step={2}
                        styles={{ input: { width: 54, textAlign: "center" } }}
                      />

                      <ActionIcon
                        radius="xl"
                        color="dark"
                        variant="outline"
                        onClick={(e) =>
                          handleIncrease(
                            e,
                            product.id,
                            parseInt(product.quantity) + 1
                          )
                        }
                      >
                        +
                      </ActionIcon>
                    </Group>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          ))}
      </div>
      <div className="container">
        <div className="d-flex justify-content-between w-100 my-5">
          <h5>
            {" "}
            {t("products.totalprice")}: {totalPrice} {t("products.jod")}
          </h5>

          <Button
            radius="xl"
            className="btn-primary-dark rounded-pill"
            disabled={cart.length === 0 ? true : ""}
            onClick={handleCheckout}
          >
            {t("cart.checkout")}{" "}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Cart;
