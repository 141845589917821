import React, { createContext, useState } from "react";
export const BranchContext = createContext();

export const BranchProvider = ({ children }) => {
    const[branch,setBranch]=useState(localStorage.getItem('branch'))

    const branchSelected = (branch) => {
        setBranch(branch);
    };
  
    const branchRemoved = () => {
        setBranch(null);
    };
  
    return (
      <BranchContext.Provider value={{ branch, branchSelected, branchRemoved }}>
        {children}
      </BranchContext.Provider>
    );
  };