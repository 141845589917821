import { useState, useEffect } from "react"; //state hook to render changes to the view
import { useTranslation } from "react-i18next";

const useFetch = (route, page="") => {
  const [data, setData] = useState(null);
  const [statusCode,setStatusCode]=useState(null);
  const [isPending, setIsPending] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const { i18n } = useTranslation();
  
  useEffect(()=>{
    setData(null)
  },[i18n.language])
  
  useEffect(() => {

    setIsPending(true)
    const abortCont = new AbortController();



        fetch(process.env.REACT_APP_BACKEND_URL+"/api/dashboard/" + route + page, {
          signal: abortCont.signal,
          headers: {
            "Content-Type": "application/json",
            resturant: process.env.REACT_APP_DB_NAME,
            lang: i18n.language,
          },
        })
          .then((res) => {
            if (!res.ok) {
              throw Error("could not fetch the data");
            }
            return res.json();
          })
          .then((results) => {
            setStatusCode(results.statusCode)
            if (page == "" || page == "0") {
              setData(results.data);
            } else {
              Object.values(results.data).map((obj) => {
                setData((oldArray) => [...oldArray, obj]);
              });
            }
            setIsPending(false);
            setErrorMsg(null);
          })
          .catch((err) => {
            if (err.name === "AbortError") {
              console.log(err.message);
            } else {
              setErrorMsg(err.message);
              setIsPending(false);
            }
          });



   

    return () => abortCont.abort();
  }, [route, page, i18n.language]);
 

  return { data, statusCode,isPending, errorMsg };
};
export default useFetch;
